// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// All available Shoootin currencies
export const ShCurrencies = [
  'CHF',
  'EUR',
  'GBP',
  'NOK',
  'RSD',
  'RUB',
  'USD',
] as const;
export type ShCurrency = typeof ShCurrencies[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// All available shooting types
export const ShShootingTypes = [
  'residentialRealEstate',
  'commercialRealEstate',
  'architect',
  'construction',
  'hotel',
  'restaurant',
  'retail',
  'event',
  'portrait',
  'other',
] as const;
export type ShShootingType = typeof ShShootingTypes[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// All offer types
export const ShOfferCategories = ['classic', 'aerial'] as const;
export type ShOfferCategory = typeof ShOfferCategories[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// All video types
export const ShVideoTypes = ['classic', 'premium'] as const;
export type ShVideoType = typeof ShVideoTypes[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// All available timezones
export const ShTimeZones = [
  'America/New_York',
  'America/Los_Angeles',
  'America/Chicago',
  'Europe/Paris',
  'Europe/London',
  'Europe/Brussels',
  'Europe/Madrid',
  'Europe/Berlin',
  'Europe/Rome',
  'Africa/Abidjan',
  'Africa/Accra',
  'Africa/Addis_Ababa',
  'Africa/Algiers',
  'Africa/Asmara',
  'Africa/Nairobi',
  'Africa/Bamako',
  'Africa/Bangui',
  'Africa/Banjul',
  'Africa/Bissau',
  'Africa/Blantyre',
  'Africa/Brazzaville',
  'Africa/Bujumbura',
  'Africa/Cairo',
  'Africa/Casablanca',
  'Africa/Ceuta',
  'Africa/Conakry',
  'Africa/Dakar',
  'Africa/Dar_es_Salaam',
  'Africa/Djibouti',
  'Africa/Douala',
  'Africa/El_Aaiun',
  'Africa/Freetown',
  'Africa/Gaborone',
  'Africa/Harare',
  'Africa/Johannesburg',
  'Africa/Juba',
  'Africa/Kampala',
  'Africa/Khartoum',
  'Africa/Kigali',
  'Africa/Kinshasa',
  'Africa/Lagos',
  'Africa/Libreville',
  'Africa/Lome',
  'Africa/Luanda',
  'Africa/Lubumbashi',
  'Africa/Lusaka',
  'Africa/Malabo',
  'Africa/Maputo',
  'Africa/Maseru',
  'Africa/Mbabane',
  'Africa/Mogadishu',
  'Africa/Monrovia',
  'Africa/Ndjamena',
  'Africa/Niamey',
  'Africa/Nouakchott',
  'Africa/Ouagadougou',
  'Africa/Porto-Novo',
  'Africa/Sao_Tome',
  'Africa/Tripoli',
  'Africa/Tunis',
  'Africa/Windhoek',
  'America/Adak',
  'America/Anchorage',
  'America/Anguilla',
  'America/Antigua',
  'America/Araguaina',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Catamarca',
  'America/Argentina/Cordoba',
  'America/Argentina/Jujuy',
  'America/Argentina/La_Rioja',
  'America/Argentina/Mendoza',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Salta',
  'America/Argentina/San_Juan',
  'America/Argentina/San_Luis',
  'America/Argentina/Tucuman',
  'America/Argentina/Ushuaia',
  'America/Aruba',
  'America/Asuncion',
  'America/Atikokan',
  'America/Bahia',
  'America/Bahia_Banderas',
  'America/Barbados',
  'America/Belem',
  'America/Belize',
  'America/Blanc-Sablon',
  'America/Boa_Vista',
  'America/Bogota',
  'America/Boise',
  'America/Cambridge_Bay',
  'America/Campo_Grande',
  'America/Cancun',
  'America/Caracas',
  'America/Cayenne',
  'America/Cayman',
  'America/Chihuahua',
  'America/Costa_Rica',
  'America/Creston',
  'America/Cuiaba',
  'America/Curacao',
  'America/Danmarkshavn',
  'America/Dawson',
  'America/Dawson_Creek',
  'America/Denver',
  'America/Detroit',
  'America/Dominica',
  'America/Edmonton',
  'America/Eirunepe',
  'America/El_Salvador',
  'America/Tijuana',
  'America/Fort_Nelson',
  'America/Indiana/Indianapolis',
  'America/Fortaleza',
  'America/Glace_Bay',
  'America/Godthab',
  'America/Goose_Bay',
  'America/Grand_Turk',
  'America/Grenada',
  'America/Guadeloupe',
  'America/Guatemala',
  'America/Guayaquil',
  'America/Guyana',
  'America/Halifax',
  'America/Havana',
  'America/Hermosillo',
  'America/Indiana/Knox',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Tell_City',
  'America/Indiana/Vevay',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Inuvik',
  'America/Iqaluit',
  'America/Jamaica',
  'America/Juneau',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Kralendijk',
  'America/La_Paz',
  'America/Lima',
  'America/Lower_Princes',
  'America/Maceio',
  'America/Managua',
  'America/Manaus',
  'America/Marigot',
  'America/Martinique',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Menominee',
  'America/Merida',
  'America/Metlakatla',
  'America/Mexico_City',
  'America/Miquelon',
  'America/Moncton',
  'America/Monterrey',
  'America/Montevideo',
  'America/Toronto',
  'America/Montserrat',
  'America/Nassau',
  'America/Nipigon',
  'America/Nome',
  'America/Noronha',
  'America/North_Dakota/Beulah',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/Ojinaga',
  'America/Panama',
  'America/Pangnirtung',
  'America/Paramaribo',
  'America/Phoenix',
  'America/Port-au-Prince',
  'America/Port_of_Spain',
  'America/Rio_Branco',
  'America/Porto_Velho',
  'America/Puerto_Rico',
  'America/Rainy_River',
  'America/Rankin_Inlet',
  'America/Recife',
  'America/Regina',
  'America/Resolute',
  'America/Santarem',
  'America/Santiago',
  'America/Santo_Domingo',
  'America/Sao_Paulo',
  'America/Scoresbysund',
  'America/Sitka',
  'America/St_Barthelemy',
  'America/St_Johns',
  'America/St_Kitts',
  'America/St_Lucia',
  'America/St_Thomas',
  'America/St_Vincent',
  'America/Swift_Current',
  'America/Tegucigalpa',
  'America/Thule',
  'America/Thunder_Bay',
  'America/Tortola',
  'America/Vancouver',
  'America/Whitehorse',
  'America/Winnipeg',
  'America/Yakutat',
  'America/Yellowknife',
  'Antarctica/Casey',
  'Antarctica/Davis',
  'Antarctica/DumontDUrville',
  'Antarctica/Macquarie',
  'Antarctica/Mawson',
  'Antarctica/McMurdo',
  'Antarctica/Palmer',
  'Antarctica/Rothera',
  'Pacific/Auckland',
  'Antarctica/Syowa',
  'Antarctica/Troll',
  'Antarctica/Vostok',
  'Arctic/Longyearbyen',
  'Asia/Aden',
  'Asia/Almaty',
  'Asia/Amman',
  'Asia/Anadyr',
  'Asia/Aqtau',
  'Asia/Aqtobe',
  'Asia/Ashgabat',
  'Asia/Baghdad',
  'Asia/Bahrain',
  'Asia/Baku',
  'Asia/Bangkok',
  'Asia/Barnaul',
  'Asia/Beirut',
  'Asia/Bishkek',
  'Asia/Brunei',
  'Asia/Kolkata',
  'Asia/Chita',
  'Asia/Choibalsan',
  'Asia/Shanghai',
  'Asia/Colombo',
  'Asia/Dhaka',
  'Asia/Damascus',
  'Asia/Dili',
  'Asia/Dubai',
  'Asia/Dushanbe',
  'Asia/Famagusta',
  'Asia/Gaza',
  'Asia/Hebron',
  'Asia/Ho_Chi_Minh',
  'Asia/Hong_Kong',
  'Asia/Hovd',
  'Asia/Irkutsk',
  'Asia/Istanbul',
  'Asia/Jakarta',
  'Asia/Jayapura',
  'Asia/Jerusalem',
  'Asia/Kabul',
  'Asia/Kamchatka',
  'Asia/Karachi',
  'Asia/Urumqi',
  'Asia/Kathmandu',
  'Asia/Khandyga',
  'Asia/Krasnoyarsk',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Asia/Kuwait',
  'Asia/Macau',
  'Asia/Magadan',
  'Asia/Makassar',
  'Asia/Manila',
  'Asia/Muscat',
  'Asia/Nicosia',
  'Asia/Novokuznetsk',
  'Asia/Novosibirsk',
  'Asia/Omsk',
  'Asia/Oral',
  'Asia/Phnom_Penh',
  'Asia/Pontianak',
  'Asia/Pyongyang',
  'Asia/Qatar',
  'Asia/Qyzylorda',
  'Asia/Yangon',
  'Asia/Riyadh',
  'Asia/Sakhalin',
  'Asia/Samarkand',
  'Asia/Seoul',
  'Asia/Singapore',
  'Asia/Srednekolymsk',
  'Asia/Taipei',
  'Asia/Tashkent',
  'Asia/Tbilisi',
  'Asia/Tehran',
  'Asia/Thimphu',
  'Asia/Tokyo',
  'Asia/Tomsk',
  'Asia/Ulaanbaatar',
  'Asia/Ust-Nera',
  'Asia/Vientiane',
  'Asia/Vladivostok',
  'Asia/Yakutsk',
  'Asia/Yekaterinburg',
  'Asia/Yerevan',
  'Atlantic/Azores',
  'Atlantic/Bermuda',
  'Atlantic/Canary',
  'Atlantic/Cape_Verde',
  'Atlantic/Faroe',
  'Europe/Oslo',
  'Atlantic/Madeira',
  'Atlantic/Reykjavik',
  'Atlantic/South_Georgia',
  'Atlantic/St_Helena',
  'Atlantic/Stanley',
  'Australia/Sydney',
  'Australia/Adelaide',
  'Australia/Brisbane',
  'Australia/Broken_Hill',
  'Australia/Currie',
  'Australia/Darwin',
  'Australia/Eucla',
  'Australia/Hobart',
  'Australia/Lord_Howe',
  'Australia/Lindeman',
  'Australia/Melbourne',
  'Australia/Perth',
  'CET',
  'CST6CDT',
  'Pacific/Easter',
  'EET',
  'EST5EDT',
  'Europe/Dublin',
  'Etc/GMT',
  'Etc/GMT+1',
  'Etc/GMT+10',
  'Etc/GMT+11',
  'Etc/GMT+12',
  'Etc/GMT+2',
  'Etc/GMT+3',
  'Etc/GMT+4',
  'Etc/GMT+5',
  'Etc/GMT+6',
  'Etc/GMT+7',
  'Etc/GMT+8',
  'Etc/GMT+9',
  'Etc/GMT-1',
  'Etc/GMT-10',
  'Etc/GMT-11',
  'Etc/GMT-12',
  'Etc/GMT-13',
  'Etc/GMT-14',
  'Etc/GMT-2',
  'Etc/GMT-3',
  'Etc/GMT-4',
  'Etc/GMT-5',
  'Etc/GMT-6',
  'Etc/GMT-7',
  'Etc/GMT-8',
  'Etc/GMT-9',
  'Etc/UCT',
  'Etc/UTC',
  'Europe/Amsterdam',
  'Europe/Andorra',
  'Europe/Astrakhan',
  'Europe/Athens',
  'Europe/Belgrade',
  'Europe/Bratislava',
  'Europe/Bucharest',
  'Europe/Budapest',
  'Europe/Busingen',
  'Europe/Chisinau',
  'Europe/Copenhagen',
  'Europe/Gibraltar',
  'Europe/Guernsey',
  'Europe/Helsinki',
  'Europe/Isle_of_Man',
  'Europe/Istanbul',
  'Europe/Jersey',
  'Europe/Kaliningrad',
  'Europe/Kiev',
  'Europe/Kirov',
  'Europe/Lisbon',
  'Europe/Ljubljana',
  'Europe/Luxembourg',
  'Europe/Malta',
  'Europe/Mariehamn',
  'Europe/Minsk',
  'Europe/Monaco',
  'Europe/Moscow',
  'Europe/Nicosia',
  'Europe/Podgorica',
  'Europe/Prague',
  'Europe/Riga',
  'Europe/Samara',
  'Europe/San_Marino',
  'Europe/Sarajevo',
  'Europe/Simferopol',
  'Europe/Skopje',
  'Europe/Sofia',
  'Europe/Stockholm',
  'Europe/Tallinn',
  'Europe/Tirane',
  'Europe/Ulyanovsk',
  'Europe/Uzhgorod',
  'Europe/Vaduz',
  'Europe/Vatican',
  'Europe/Vienna',
  'Europe/Vilnius',
  'Europe/Volgograd',
  'Europe/Warsaw',
  'Europe/Zagreb',
  'Europe/Zaporozhye',
  'Europe/Zurich',
  'Indian/Antananarivo',
  'Indian/Chagos',
  'Indian/Christmas',
  'Indian/Cocos',
  'Indian/Comoro',
  'Indian/Kerguelen',
  'Indian/Mahe',
  'Indian/Maldives',
  'Indian/Mauritius',
  'Indian/Mayotte',
  'Indian/Reunion',
  'Pacific/Kwajalein',
  'MET',
  'MST7MDT',
  'Pacific/Chatham',
  'PST8PDT',
  'Pacific/Apia',
  'Pacific/Bougainville',
  'Pacific/Chuuk',
  'Pacific/Efate',
  'Pacific/Enderbury',
  'Pacific/Fakaofo',
  'Pacific/Fiji',
  'Pacific/Funafuti',
  'Pacific/Galapagos',
  'Pacific/Gambier',
  'Pacific/Guadalcanal',
  'Pacific/Guam',
  'Pacific/Honolulu',
  'Pacific/Johnston',
  'Pacific/Kiritimati',
  'Pacific/Kosrae',
  'Pacific/Majuro',
  'Pacific/Marquesas',
  'Pacific/Midway',
  'Pacific/Nauru',
  'Pacific/Niue',
  'Pacific/Norfolk',
  'Pacific/Noumea',
  'Pacific/Pago_Pago',
  'Pacific/Palau',
  'Pacific/Pitcairn',
  'Pacific/Pohnpei',
  'Pacific/Port_Moresby',
  'Pacific/Rarotonga',
  'Pacific/Saipan',
  'Pacific/Tahiti',
  'Pacific/Tarawa',
  'Pacific/Tongatapu',
  'Pacific/Wake',
  'Pacific/Wallis',
  'UTC',
  'WET',
  'EST',
  'HST',
  'MST',
] as const;
export type ShTimeZone = typeof ShTimeZones[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// All photo offer option categories
export const ShOfferPhotoOptionCategories = [
  'allOption',
  'restaurantOption',
  'serviceOption',
  'foodingOption',
  'hostingOption',
  'scanOption',
  'portraitOption',
] as const;
export type ShOfferPhotoOptionCategory = typeof ShOfferPhotoOptionCategories[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// All client roles
export const ShClientUserRoles = [
  'OWNER',
  'ADMIN',
  'CONSULTANT',
  'ASSISTANT',
] as const;
export type ShClientUserRole = typeof ShClientUserRoles[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// Payment methods
export const ShPaymentMethods = [
  'CREDIT_CARD',
  'CREDIT',
  'UNLIMITED',
  'INVOICE',
  'FREE',
  'ORDER',
] as const;
export type ShPaymentMethod = typeof ShPaymentMethods[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// Help Modals
export const ShOrderHelpModalNames = [
  'offerBasic',
  'offerCustom',
  'offerPhoto',
  'offerVideo',
  'offerScan',
  'stripe',
  'paymentNewCreditCard',
  'paymentExistingCreditCard',
  'paymentCredit',
  'paymentUnlimited',
  'paymentOrder',
  'paymentStripe',
] as const;
export type ShOrderHelpModalName = typeof ShOrderHelpModalNames[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// Video Review User Types
export const ShVideoReviewUsers = [
  'unlogged',
  'client',
  'photographer',
  'editor',
  'shoootin',
] as const;
export type ShVideoReviewUser = typeof ShVideoReviewUsers[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// All VS styles
export const ShVsStyles = [
  'modern',
  'scandinavian',
  'luxury',
  'transitional',
] as const;
export type ShVsStyle = typeof ShVsStyles[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// All VS rooms
export const ShVsRooms = [
  'livingRoom',
  'diningRoom',
  'livingDiningRoom',
  'studio',
  'bedRoom',
  'bathroom',
  'kitchen',
  'outdoors',
  'office',
  'openSpace',
  'meetingRoom',
  'lounge',
  'kidsBedroom',
  'entertainment',
  'garage',
  'gym',
  'others',
] as const;
export type ShVsRoom = typeof ShVsRooms[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// All VS Status
export const ShVsStatusList = [
  'CLIENT_SUBMITTED',
  'CLIENT_VALIDATION',
  'IN_PRODUCTION',
  'COMPLETED',
  'CANCELLED',
] as const;
export type ShVsStatus = typeof ShVsStatusList[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// All Shoootin companies
export const ShCompanyConfigs = {
  Shoootin_FRANCE: {
    company: 'Shoootin_FRANCE',
    companyName: 'Shoootin France',
    key: 'Shoootin_FRANCE',
  },
  Shoootin_LTD: {
    company: 'Shoootin_LTD',
    companyName: 'Shoootin LTD',
    key: 'Shoootin_LTD',
  },
  Shoootin_Inc: {
    company: 'Shoootin_Inc',
    companyName: 'Shoootin Inc',
    key: 'Shoootin_Inc',
  },
} as const;

export const ShCompanies = [
  'Shoootin_FRANCE',
  'Shoootin_LTD',
  'Shoootin_Inc',
] as const;

export type ShCompany = typeof ShCompanies[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// All available Shoootin locales
export const ShLocaleConfigs = {
  fr_FR: {
    locale: 'fr_FR',
    language: 'fr',
    label: 'Français',
    countryName: 'France',
    languageName: 'Français',
    localisedCountryName: 'France',
    country: 'FR',
    key: 'fr_FR',
  },
  fr_BE: {
    locale: 'fr_BE',
    language: 'fr',
    label: 'Français (BE)',
    countryName: 'Belgium',
    languageName: 'Français',
    localisedCountryName: 'Belgique',
    country: 'BE',
    key: 'fr_BE',
  },
  de_DE: {
    locale: 'de_DE',
    language: 'de',
    label: 'Deutsch',
    countryName: 'Germany',
    languageName: 'Deutsch',
    localisedCountryName: 'Deutschland',
    country: 'DE',
    key: 'de_DE',
  },
  en_GB: {
    locale: 'en_GB',
    language: 'en',
    label: 'English (UK)',
    countryName: 'United Kingdom',
    languageName: 'English',
    localisedCountryName: 'United Kingdom',
    country: 'GB',
    key: 'en_GB',
  },
  es_ES: {
    locale: 'es_ES',
    language: 'es',
    label: 'Español',
    countryName: 'Spain',
    languageName: 'Español',
    localisedCountryName: 'España',
    country: 'ES',
    key: 'es_ES',
  },
  en_US: {
    locale: 'en_US',
    language: 'en',
    label: 'English (US)',
    countryName: 'United States',
    languageName: 'English',
    localisedCountryName: 'United States',
    country: 'US',
    key: 'en_US',
  },
  it_IT: {
    locale: 'it_IT',
    language: 'it',
    label: 'Italiano',
    countryName: 'Italy',
    languageName: 'Italiano',
    localisedCountryName: 'Italia',
    country: 'IT',
    key: 'it_IT',
  },
  pt_PT: {
    locale: 'pt_PT',
    language: 'pt',
    label: 'Portuguese',
    countryName: 'Portugal',
    languageName: 'Portuguese',
    localisedCountryName: 'Portugal',
    country: 'PT',
    key: 'pt_PT',
  },
  el_GR: {
    locale: 'el_GR',
    language: 'el',
    label: 'Greek',
    countryName: 'Greece',
    languageName: 'Greek',
    localisedCountryName: 'Greece',
    country: 'GR',
    key: 'el_GR',
  },
  nl_NL: {
    locale: 'nl_NL',
    language: 'nl',
    label: 'Dutch',
    countryName: 'Netherlands',
    languageName: 'Dutch',
    localisedCountryName: 'Nederland',
    country: 'NL',
    key: 'nl_NL',
  },
  fr_LU: {
    locale: 'fr_LU',
    language: 'fr',
    label: 'Français (LU)',
    countryName: 'Luxembourg',
    languageName: 'Français',
    localisedCountryName: 'Luxembourg',
    country: 'LU',
    key: 'fr_LU',
  },
  en_MT: {
    locale: 'en_MT',
    language: 'en',
    label: 'English (MT)',
    countryName: 'Malta',
    languageName: 'English',
    localisedCountryName: 'Malta',
    country: 'MT',
    key: 'en_MT',
  },
} as const;

export const ShLocales = [
  'fr_FR',
  'fr_BE',
  'de_DE',
  'en_GB',
  'es_ES',
  'en_US',
  'it_IT',
  'pt_PT',
  'el_GR',
  'nl_NL',
  'fr_LU',
  'en_MT',
] as const;

export type ShLocale = typeof ShLocales[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// All available Shoootin languages
export const ShLanguageLocaleConfigs = {
  en: { key: 'en', value: 'English' },
  fr: { key: 'fr', value: 'Français' },
  de: { key: 'de', value: 'Deutsch' },
  el: { key: 'el', value: 'Greek' },
  es: { key: 'es', value: 'Español' },
  it: { key: 'it', value: 'Italiano' },
  nl: { key: 'nl', value: 'Dutch' },
  pt: { key: 'pt', value: 'Portuguese' },
} as const;

export const ShLanguageLocales = [
  'en',
  'fr',
  'de',
  'el',
  'es',
  'it',
  'nl',
  'pt',
] as const;

export type ShLanguageLocale = typeof ShLanguageLocales[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// Configuration for Shoootin countries
export const ShCountryConfigs = {
  FR: {
    code: 'FR',
    label: 'France',
    centerLatLng: { lat: 46.227638, lng: 2.213749 },
    forPhotographer: true,
    forOrder: true,
    zoom: 6,
    extraCountryRestrictions: ['MC'],
    metricSystem: true,
    currency: 'EUR',
    company: 'Shoootin_FRANCE',
    key: 'FR',
    europe: true,
  },
  DE: {
    code: 'DE',
    label: 'Germany',
    centerLatLng: { lat: 51.165691, lng: 10.451526 },
    forPhotographer: true,
    forOrder: true,
    zoom: 6,
    extraCountryRestrictions: [],
    metricSystem: true,
    currency: 'EUR',
    company: 'Shoootin_FRANCE',
    key: 'DE',
    europe: true,
  },
  GB: {
    code: 'GB',
    label: 'United Kingdom',
    centerLatLng: { lat: 55.378051, lng: -3.435973 },
    forPhotographer: true,
    forOrder: true,
    zoom: 6,
    extraCountryRestrictions: [],
    metricSystem: false,
    currency: 'GBP',
    company: 'Shoootin_LTD',
    key: 'GB',
    europe: true,
  },
  ES: {
    code: 'ES',
    label: 'Spain',
    centerLatLng: { lat: 40.463667, lng: -3.74922 },
    forPhotographer: true,
    forOrder: true,
    zoom: 6,
    extraCountryRestrictions: [],
    metricSystem: true,
    currency: 'EUR',
    company: 'Shoootin_FRANCE',
    key: 'ES',
    europe: true,
  },
  BE: {
    code: 'BE',
    label: 'Belgium',
    centerLatLng: { lat: 50.503887, lng: 4.469936 },
    forPhotographer: true,
    forOrder: true,
    zoom: 7,
    extraCountryRestrictions: [],
    metricSystem: true,
    currency: 'EUR',
    company: 'Shoootin_FRANCE',
    key: 'BE',
    europe: true,
  },
  US: {
    code: 'US',
    label: 'United States',
    centerLatLng: { lat: 40.73061, lng: -73.935242 },
    forPhotographer: true,
    forOrder: true,
    zoom: 10,
    extraCountryRestrictions: [],
    metricSystem: false,
    currency: 'USD',
    company: 'Shoootin_Inc',
    key: 'US',
    europe: false,
  },
  GR: {
    code: 'GR',
    label: 'Greece',
    centerLatLng: { lat: 37.98333333, lng: 23.733333 },
    forPhotographer: true,
    forOrder: false,
    zoom: 7,
    extraCountryRestrictions: [],
    metricSystem: true,
    currency: 'EUR',
    company: 'Shoootin_FRANCE',
    key: 'GR',
    europe: true,
  },
  IT: {
    code: 'IT',
    label: 'Italy',
    centerLatLng: { lat: 41.9, lng: 12.483333 },
    forPhotographer: true,
    forOrder: true,
    zoom: 7,
    extraCountryRestrictions: [],
    metricSystem: true,
    currency: 'EUR',
    company: 'Shoootin_FRANCE',
    key: 'IT',
    europe: true,
  },
  PT: {
    code: 'PT',
    label: 'Portugal',
    centerLatLng: { lat: 38.71666667, lng: -9.133333 },
    forPhotographer: true,
    forOrder: true,
    zoom: 7,
    extraCountryRestrictions: [],
    metricSystem: true,
    currency: 'EUR',
    company: 'Shoootin_FRANCE',
    key: 'PT',
    europe: true,
  },
  LU: {
    code: 'LU',
    label: 'Luxembourg',
    centerLatLng: { lat: 49.6, lng: 6.116667 },
    forPhotographer: true,
    forOrder: true,
    zoom: 11,
    extraCountryRestrictions: [],
    metricSystem: true,
    currency: 'EUR',
    company: 'Shoootin_FRANCE',
    key: 'LU',
    europe: true,
  },
  MT: {
    code: 'MT',
    label: 'Malta',
    centerLatLng: { lat: 35.88333333, lng: 14.5 },
    forPhotographer: true,
    forOrder: false,
    zoom: 11,
    extraCountryRestrictions: [],
    metricSystem: true,
    currency: 'EUR',
    company: 'Shoootin_FRANCE',
    key: 'MT',
    europe: true,
  },
  NL: {
    code: 'NL',
    label: 'Netherlands',
    centerLatLng: { lat: 52.35, lng: 4.916667 },
    forPhotographer: true,
    forOrder: false,
    zoom: 8,
    extraCountryRestrictions: [],
    metricSystem: true,
    currency: 'EUR',
    company: 'Shoootin_FRANCE',
    key: 'NL',
    europe: true,
  },
  CH: {
    code: 'CH',
    label: 'Switzerland',
    centerLatLng: { lat: 46.91666667, lng: 7.466667 },
    forPhotographer: true,
    forOrder: false,
    zoom: 8,
    extraCountryRestrictions: [],
    metricSystem: true,
    currency: 'CHF',
    company: 'Shoootin_FRANCE',
    key: 'CH',
    europe: false,
  },
} as const;

export const ShCountries = [
  'FR',
  'DE',
  'GB',
  'ES',
  'BE',
  'US',
  'GR',
  'IT',
  'PT',
  'LU',
  'MT',
  'NL',
  'CH',
] as const;

export type ShCountry = typeof ShCountries[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// Configuration for Watermark position
export const ShWatermarkPositionConfigs = {
  NorthWest: {
    position: 'NorthWest',
    top: 0,
    right: 67,
    bottom: 67,
    left: 0,
    key: 'NorthWest',
  },
  North: {
    position: 'North',
    top: 0,
    right: 33,
    bottom: 67,
    left: 33,
    key: 'North',
  },
  NorthEast: {
    position: 'NorthEast',
    top: 0,
    right: 0,
    bottom: 67,
    left: 67,
    key: 'NorthEast',
  },
  West: {
    position: 'West',
    top: 33,
    right: 67,
    bottom: 33,
    left: 0,
    key: 'West',
  },
  Center: {
    position: 'Center',
    top: 33,
    right: 33,
    bottom: 33,
    left: 33,
    key: 'Center',
  },
  East: {
    position: 'East',
    top: 33,
    right: 0,
    bottom: 33,
    left: 67,
    key: 'East',
  },
  SouthWest: {
    position: 'SouthWest',
    top: 67,
    right: 67,
    bottom: 0,
    left: 0,
    key: 'SouthWest',
  },
  South: {
    position: 'South',
    top: 67,
    right: 33,
    bottom: 0,
    left: 33,
    key: 'South',
  },
  SouthEast: {
    position: 'SouthEast',
    top: 67,
    right: 0,
    bottom: 0,
    left: 67,
    key: 'SouthEast',
  },
} as const;

export const ShWatermarkPositions = [
  'NorthWest',
  'North',
  'NorthEast',
  'West',
  'Center',
  'East',
  'SouthWest',
  'South',
  'SouthEast',
] as const;

export type ShWatermarkPosition = typeof ShWatermarkPositions[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// All type of clients
export const ShClientTypesConfigs = {
  residentialRealEstate: { key: 'residentialRealEstate', value: 'Residential' },
  commercialRealEstate: { key: 'commercialRealEstate', value: 'Commercial' },
  propertyManagement: {
    key: 'propertyManagement',
    value: 'Property management',
  },
  mandataryAgent: { key: 'mandataryAgent', value: 'Mandatary agent' },
  architect: { key: 'architect', value: 'Architect' },
  construction: { key: 'construction', value: 'Construction' },
  hotel: { key: 'hotel', value: 'Hotel' },
  restaurant: { key: 'restaurant', value: 'Restaurant' },
  individual: { key: 'individual', value: 'Individual' },
  other: { key: 'other', value: 'Other' },
} as const;

export const ShClientTypes = [
  'residentialRealEstate',
  'commercialRealEstate',
  'propertyManagement',
  'mandataryAgent',
  'architect',
  'construction',
  'hotel',
  'restaurant',
  'individual',
  'other',
] as const;

export type ShClientType = typeof ShClientTypes[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// All available shooting client states
export const ShShootingClientStatesConfigs = {
  waitingConfirmation: {
    key: 'waitingConfirmation',
    value: 'Waiting confirmation',
  },
  waitingPhotographerConfirmation: {
    key: 'waitingPhotographerConfirmation',
    value: 'Waiting photographer',
  },
  confirmed: { key: 'confirmed', value: 'Confirmed' },
  ready: { key: 'ready', value: 'Delivered' },
  cancelled: { key: 'cancelled', value: 'Cancelled' },
  old: { key: 'old', value: 'Old' },
} as const;

export const ShShootingClientStates = [
  'waitingConfirmation',
  'waitingPhotographerConfirmation',
  'confirmed',
  'ready',
  'cancelled',
  'old',
] as const;

export type ShShootingClientState = typeof ShShootingClientStates[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// All available shooting review states
export const ShShootingReviewStatesConfigs = {
  waitingConfirmation: {
    key: 'waitingConfirmation',
    value: 'Waiting confirmation',
  },
  started: { key: 'started', value: 'Started' },
  finished: { key: 'finished', value: 'Finished' },
  noState: { key: 'noState', value: 'No state' },
} as const;

export const ShShootingReviewStates = [
  'waitingConfirmation',
  'started',
  'finished',
  'noState',
] as const;

export type ShShootingReviewState = typeof ShShootingReviewStates[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// All available shooting photographer states
export const ShShootingPhotographerStatesConfigs = {
  waitingConfirmation: {
    key: 'waitingConfirmation',
    value: 'Waiting confirmation',
  },
  confirmed: { key: 'confirmed', value: 'Confirmed' },
  finished: { key: 'finished', value: 'Finished' },
  uploadFinished: { key: 'uploadFinished', value: 'Uploaded' },
  ready: { key: 'ready', value: 'Delivered' },
} as const;

export const ShShootingPhotographerStates = [
  'waitingConfirmation',
  'confirmed',
  'finished',
  'uploadFinished',
  'ready',
] as const;

export type ShShootingPhotographerState = typeof ShShootingPhotographerStates[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// All available shooting videast states
export const ShShootingVideastStatesConfigs = {
  waiting: { key: 'waiting', value: 'Waiting' },
  loading: { key: 'loading', value: 'Loading' },
  loaded: { key: 'loaded', value: 'Loaded' },
} as const;

export const ShShootingVideastStates = [
  'waiting',
  'loading',
  'loaded',
] as const;

export type ShShootingVideastState = typeof ShShootingVideastStates[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// All available shooting photo edit states
export const ShShootingPhotoEditingStatesConfigs = {
  waitingConfirmation: {
    key: 'waitingConfirmation',
    value: 'Waiting confirmation',
  },
  confirmed: { key: 'confirmed', value: 'Confirmed' },
  uploadFinished: { key: 'uploadFinished', value: 'Loaded' },
  reedit: { key: 'reedit', value: 'Re-edit' },
} as const;

export const ShShootingPhotoEditingStates = [
  'waitingConfirmation',
  'confirmed',
  'uploadFinished',
  'reedit',
] as const;

export type ShShootingPhotoEditingState = typeof ShShootingPhotoEditingStates[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// All available shooting video edit states
export const ShShootingVideoEditingStatesConfigs = {
  waitingConfirmation: {
    key: 'waitingConfirmation',
    value: 'Waiting confirmation',
  },
  confirmed: { key: 'confirmed', value: 'Confirmed' },
  stuck: { key: 'stuck', value: 'Stuck' },
  changes: { key: 'changes', value: 'Changes' },
  loaded: { key: 'loaded', value: 'Loaded' },
} as const;

export const ShShootingVideoEditingStates = [
  'waitingConfirmation',
  'confirmed',
  'stuck',
  'changes',
  'loaded',
] as const;

export type ShShootingVideoEditingState = typeof ShShootingVideoEditingStates[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// All available photographer availabilities
export const ShPhotographerAvailabilitiesConfig = {
  fullTime: { key: 'fullTime', value: 'Full time' },
  OneTwoDays: { key: 'OneTwoDays', value: '1 or 2 days' },
  ThreeFourDays: { key: 'ThreeFourDays', value: '3 to 4 days' },
  OnlyWeekend: { key: 'OnlyWeekend', value: 'Week-end' },
} as const;

export const ShPhotographerAvailabilities = [
  'fullTime',
  'OneTwoDays',
  'ThreeFourDays',
  'OnlyWeekend',
] as const;

export type ShPhotographerAvailability = typeof ShPhotographerAvailabilities[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// All available photographer transportation methods
export const ShPhotographerTransportationsConfig = {
  foot: { key: 'foot', value: 'Foot' },
  bike: { key: 'bike', value: 'Bike' },
  moto: { key: 'moto', value: 'Moto' },
  car: { key: 'car', value: 'Car' },
} as const;

export const ShPhotographerTransportations = [
  'foot',
  'bike',
  'moto',
  'car',
] as const;

export type ShPhotographerTransportation = typeof ShPhotographerTransportations[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// All offer groups
export const ShOfferGroupsConfig = {
  beyond: {
    offerGroup: 'beyond',
    title: 'enums_offerGroup_title_beyond',
    description: 'enums_offerGroup_description_beyond',
    intercomArticle: null,
    key: 'beyond',
  },
  bi_city: {
    offerGroup: 'bi_city',
    title: 'enums_offerGroup_title_bi_city',
    description: 'enums_offerGroup_description_bi_city',
    intercomArticle: null,
    key: 'bi_city',
  },
} as const;

export const ShOfferGroups = ['beyond', 'bi_city'] as const;

export type ShOfferGroup = typeof ShOfferGroups[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// All type of Matterport cameras
export const ShMatterportMaterialsConfig = {
  Matterport_Pro2: { key: 'Matterport_Pro2', value: 'MP Pro2' },
  Ricoh_Z1: { key: 'Ricoh_Z1', value: 'Z1' },
} as const;

export const ShMatterportMaterials = ['Matterport_Pro2', 'Ricoh_Z1'] as const;

export type ShMatterportMaterial = typeof ShMatterportMaterials[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// All  Matterport accounts
export const ShMatterportAccountsConfig = {
  Shoootin_FRANCE: { key: 'Shoootin_FRANCE', value: 'Shoootin France' },
  JLL_FRANCE: { key: 'JLL_FRANCE', value: 'JLL France' },
  JLL_UK: { key: 'JLL_UK', value: 'JLL UK' },
} as const;

export const ShMatterportAccounts = [
  'Shoootin_FRANCE',
  'JLL_FRANCE',
  'JLL_UK',
] as const;

export type ShMatterportAccount = typeof ShMatterportAccounts[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// All Prospect Photographer States
export const ShProspectPhotographerStatesConfigs = {
  toReview: {
    state: 'toReview',
    description: 'To Review',
    canChangeHisApplication: true,
    substates: [],
    key: 'toReview',
  },
  validated: {
    state: 'validated',
    description: 'Validated',
    canChangeHisApplication: false,
    substates: [
      'validatedZoneNotEnoughShootings',
      'validatedWaitingForCallSchedule',
      'validatedWaitingForCall',
    ],
    key: 'validated',
  },
  training: {
    state: 'training',
    description: 'Training',
    canChangeHisApplication: false,
    substates: [
      'trainingTestUpload',
      'trainingTestReview',
      'trainingDocsUpload',
      'trainingDocsValidation',
    ],
    key: 'training',
  },
  refused: {
    state: 'refused',
    description: 'Refused',
    canChangeHisApplication: true,
    substates: [
      'refusedIncomplete',
      'refusedBadMaterial',
      'refusedBadMaterialCase',
      'refusedBadMaterialOpticsCanonFullFrame',
      'refusedBadMaterialOpticsCanonASPC',
      'refusedBadMaterialOpticsNikonFullFrame',
      'refusedBadMaterialOpticsNikonASPC',
      'refusedOther',
    ],
    key: 'refused',
  },
} as const;

export const ShProspectPhotographerStates = [
  'toReview',
  'validated',
  'training',
  'refused',
] as const;

export type ShProspectPhotographerState = typeof ShProspectPhotographerStates[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// All Prospect Photographer Substates
export const ShProspectPhotographerSubstatesConfigs = {
  noSubstate: {
    substate: 'noSubstate',
    description: 'N/A',
    needsActionFromShoootin: false,
    needsActionFromProspect: false,
    canHaveScheduleLink: false,
    key: 'noSubstate',
  },
  validatedZoneNotEnoughShootings: {
    substate: 'validatedZoneNotEnoughShootings',
    description: 'Waiting list',
    needsActionFromShoootin: false,
    needsActionFromProspect: false,
    canHaveScheduleLink: false,
    key: 'validatedZoneNotEnoughShootings',
  },
  validatedWaitingForCallSchedule: {
    substate: 'validatedWaitingForCallSchedule',
    description: 'Waiting for call schedule',
    needsActionFromShoootin: false,
    needsActionFromProspect: true,
    canHaveScheduleLink: true,
    key: 'validatedWaitingForCallSchedule',
  },
  validatedWaitingForCall: {
    substate: 'validatedWaitingForCall',
    description: 'Call booked',
    needsActionFromShoootin: false,
    needsActionFromProspect: false,
    canHaveScheduleLink: true,
    key: 'validatedWaitingForCall',
  },
  trainingDocsUpload: {
    substate: 'trainingDocsUpload',
    description: 'Waiting docs upload',
    needsActionFromShoootin: false,
    needsActionFromProspect: true,
    canHaveScheduleLink: false,
    key: 'trainingDocsUpload',
  },
  trainingDocsValidation: {
    substate: 'trainingDocsValidation',
    description: 'Waiting docs validation',
    needsActionFromShoootin: true,
    needsActionFromProspect: false,
    canHaveScheduleLink: false,
    key: 'trainingDocsValidation',
  },
  trainingTestUpload: {
    substate: 'trainingTestUpload',
    description: 'Waiting test upload',
    needsActionFromShoootin: false,
    needsActionFromProspect: true,
    canHaveScheduleLink: false,
    key: 'trainingTestUpload',
  },
  trainingTestReview: {
    substate: 'trainingTestReview',
    description: 'Test to review',
    needsActionFromShoootin: true,
    needsActionFromProspect: false,
    canHaveScheduleLink: false,
    key: 'trainingTestReview',
  },
  refusedWorksForCompetition: {
    substate: 'refusedWorksForCompetition',
    description: 'Works for the competition',
    needsActionFromShoootin: false,
    needsActionFromProspect: false,
    canHaveScheduleLink: false,
    key: 'refusedWorksForCompetition',
  },
  refusedBadContact: {
    substate: 'refusedBadContact',
    description: 'Inconclusive contact',
    needsActionFromShoootin: false,
    needsActionFromProspect: false,
    canHaveScheduleLink: false,
    key: 'refusedBadContact',
  },
  refusedBadProfil: {
    substate: 'refusedBadProfil',
    description: 'Bad Profile',
    needsActionFromShoootin: false,
    needsActionFromProspect: false,
    canHaveScheduleLink: false,
    key: 'refusedBadProfil',
  },
  refusedBadZone: {
    substate: 'refusedBadZone',
    description: 'Bad zone',
    needsActionFromShoootin: false,
    needsActionFromProspect: false,
    canHaveScheduleLink: false,
    key: 'refusedBadZone',
  },
  refusedBadMaterial: {
    substate: 'refusedBadMaterial',
    description: 'Incorrect material',
    needsActionFromShoootin: false,
    needsActionFromProspect: false,
    canHaveScheduleLink: false,
    key: 'refusedBadMaterial',
  },
  refusedBadMaterialCase: {
    substate: 'refusedBadMaterialCase',
    description: 'Incorrect Case',
    needsActionFromShoootin: false,
    needsActionFromProspect: false,
    canHaveScheduleLink: false,
    key: 'refusedBadMaterialCase',
  },
  refusedBadMaterialOpticsCanonFullFrame: {
    substate: 'refusedBadMaterialOpticsCanonFullFrame',
    description: 'Incorrect Lens / Canon Full Frame',
    needsActionFromShoootin: false,
    needsActionFromProspect: false,
    canHaveScheduleLink: false,
    key: 'refusedBadMaterialOpticsCanonFullFrame',
  },
  refusedBadMaterialOpticsCanonASPC: {
    substate: 'refusedBadMaterialOpticsCanonASPC',
    description: 'Incorrect Lens / Canon ASPC',
    needsActionFromShoootin: false,
    needsActionFromProspect: false,
    canHaveScheduleLink: false,
    key: 'refusedBadMaterialOpticsCanonASPC',
  },
  refusedBadMaterialOpticsNikonFullFrame: {
    substate: 'refusedBadMaterialOpticsNikonFullFrame',
    description: 'Incorrect Lens / Nikon Full Frame',
    needsActionFromShoootin: false,
    needsActionFromProspect: false,
    canHaveScheduleLink: false,
    key: 'refusedBadMaterialOpticsNikonFullFrame',
  },
  refusedBadMaterialOpticsNikonASPC: {
    substate: 'refusedBadMaterialOpticsNikonASPC',
    description: 'Incorrect Lens / Nikon ASPC',
    needsActionFromShoootin: false,
    needsActionFromProspect: false,
    canHaveScheduleLink: false,
    key: 'refusedBadMaterialOpticsNikonASPC',
  },
  refusedOther: {
    substate: 'refusedOther',
    description: 'Other',
    needsActionFromShoootin: false,
    needsActionFromProspect: false,
    canHaveScheduleLink: false,
    key: 'refusedOther',
  },
  refusedIncomplete: {
    substate: 'refusedIncomplete',
    description: 'Incomplete',
    needsActionFromShoootin: false,
    needsActionFromProspect: false,
    canHaveScheduleLink: false,
    key: 'refusedIncomplete',
  },
  refusedBadTest: {
    substate: 'refusedBadTest',
    description: 'Inconclusive test',
    needsActionFromShoootin: false,
    needsActionFromProspect: false,
    canHaveScheduleLink: false,
    key: 'refusedBadTest',
  },
} as const;

export const ShProspectPhotographerSubstates = [
  'noSubstate',
  'validatedZoneNotEnoughShootings',
  'validatedWaitingForCallSchedule',
  'validatedWaitingForCall',
  'trainingDocsUpload',
  'trainingDocsValidation',
  'trainingTestUpload',
  'trainingTestReview',
  'refusedWorksForCompetition',
  'refusedBadContact',
  'refusedBadProfil',
  'refusedBadZone',
  'refusedBadMaterial',
  'refusedBadMaterialCase',
  'refusedBadMaterialOpticsCanonFullFrame',
  'refusedBadMaterialOpticsCanonASPC',
  'refusedBadMaterialOpticsNikonFullFrame',
  'refusedBadMaterialOpticsNikonASPC',
  'refusedOther',
  'refusedIncomplete',
  'refusedBadTest',
] as const;

export type ShProspectPhotographerSubstate = typeof ShProspectPhotographerSubstates[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// All Payment emails
export const ShPaymentEmailsConfig = {
  firstSend: { key: 'firstSend', value: 'First send' },
  firstFollowUp: { key: 'firstFollowUp', value: 'First follow up' },
  lastFollowUpWithoutFees: {
    key: 'lastFollowUpWithoutFees',
    value: 'Last follow up w/o fees',
  },
  followUpWithCollectionFees: {
    key: 'followUpWithCollectionFees',
    value: 'Follow up w/fees',
  },
  followUpWithCollectionFeesAndPenalties: {
    key: 'followUpWithCollectionFeesAndPenalties',
    value: 'Follow up w/fees & penalties',
  },
  sepa: { key: 'sepa', value: 'Sepa' },
} as const;

export const ShPaymentEmails = [
  'firstSend',
  'firstFollowUp',
  'lastFollowUpWithoutFees',
  'followUpWithCollectionFees',
  'followUpWithCollectionFeesAndPenalties',
  'sepa',
] as const;

export type ShPaymentEmail = typeof ShPaymentEmails[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// All Photographer feedbacks
export const ShPhotographerFeedbacksConfig = {
  sensor: {
    feedback: 'sensor',
    shortName: 'Sensor',
    instruction: 'common_photographer_feedbacks_sensor',
    key: 'sensor',
  },
  blur: {
    feedback: 'blur',
    shortName: 'Blur',
    instruction: 'common_photographer_feedbacks_blurryPhotos',
    key: 'blur',
  },
  lines: {
    feedback: 'lines',
    shortName: 'Lines',
    instruction: 'common_photographer_feedbacks_lines',
    key: 'lines',
  },
  homeStaging: {
    feedback: 'homeStaging',
    shortName: 'Home staging',
    instruction: 'common_photographer_feedbacks_homeStaging',
    key: 'homeStaging',
  },
  windows: {
    feedback: 'windows',
    shortName: 'Windows',
    instruction: 'common_photographer_feedbacks_windows',
    key: 'windows',
  },
  brief: {
    feedback: 'brief',
    shortName: 'Brief',
    instruction: 'common_photographer_feedbacks_brief',
    key: 'brief',
  },
  aperture: {
    feedback: 'aperture',
    shortName: 'Aperture ',
    instruction: 'common_photographer_feedbacks_aperture',
    key: 'aperture',
  },
  notEnoughPhotos: {
    feedback: 'notEnoughPhotos',
    shortName: 'Not enough photos',
    instruction: 'common_photographer_feedbacks_notEnoughPhotos',
    key: 'notEnoughPhotos',
  },
  tooMuchPhotos: {
    feedback: 'tooMuchPhotos',
    shortName: 'Too much photos',
    instruction: 'common_photographer_feedbacks_tooMuchPhotos',
    key: 'tooMuchPhotos',
  },
  lateUpload: {
    feedback: 'lateUpload',
    shortName: 'Late upload',
    instruction: 'common_photographer_feedbacks_lateUpload',
    key: 'lateUpload',
  },
} as const;

export const ShPhotographerFeedbacks = [
  'sensor',
  'blur',
  'lines',
  'homeStaging',
  'windows',
  'brief',
  'aperture',
  'notEnoughPhotos',
  'tooMuchPhotos',
  'lateUpload',
] as const;

export type ShPhotographerFeedback = typeof ShPhotographerFeedbacks[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// All Editing feedbacks
export const ShEditingFeedbacksConfig = {
  colorsInaccuracy: {
    feedback: 'colorsInaccuracy',
    shortName: 'HDR',
    name: 'HDR blurry',
    instruction:
      'Could you please download the originals and choose the best exposure and re-edit this image as the HDR file is blurry? Please make sure the colours and luminosity of the final image match the rest of the batch.',
    key: 'colorsInaccuracy',
  },
  sensorDust: {
    feedback: 'sensorDust',
    shortName: 'Dust',
    name: 'Dust on Sensor',
    instruction: 'Please remove sensor dusts.',
    key: 'sensorDust',
  },
  underExposed: {
    feedback: 'underExposed',
    shortName: 'Exposure +',
    name: 'Image underexposed',
    instruction: 'Please increase exposure.',
    key: 'underExposed',
  },
  overExposed: {
    feedback: 'overExposed',
    shortName: 'Exposure -',
    name: 'Image overexposed',
    instruction: 'Please reduce global exposure.',
    key: 'overExposed',
  },
  tooMuchContrast: {
    feedback: 'tooMuchContrast',
    shortName: 'Contrasts -',
    name: 'Image too contrasted',
    instruction: 'Please reduce contrasts.',
    key: 'tooMuchContrast',
  },
  tooMuchGrey: {
    feedback: 'tooMuchGrey',
    shortName: 'Contrasts +',
    name: 'Image too grey',
    instruction: 'Please increase contrasts.',
    key: 'tooMuchGrey',
  },
  windows: {
    feedback: 'windows',
    shortName: 'Windows',
    name: 'Windows overexposed',
    instruction:
      'The window look too obvious and fake - Please make it brighter and slightly less saturated, so it looks more realistic.',
    key: 'windows',
  },
  photographerCamera: {
    feedback: 'photographerCamera',
    shortName: 'Camera',
    name: 'Photographer/Camera visible',
    instruction: 'Please remove the shadow of the camera/photographer.',
    key: 'photographerCamera',
  },
} as const;

export const ShEditingFeedbacks = [
  'colorsInaccuracy',
  'sensorDust',
  'underExposed',
  'overExposed',
  'tooMuchContrast',
  'tooMuchGrey',
  'windows',
  'photographerCamera',
] as const;

export type ShEditingFeedback = typeof ShEditingFeedbacks[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// All shooting logs descriptions
export const ShShootingLogsConfigs = {
  clientOrder: { key: 'clientOrder', value: 'Client order' },
  clientConfirmation: {
    key: 'clientConfirmation',
    value: 'Client confirmation',
  },
  clientModification: {
    key: 'clientModification',
    value: 'Client modification',
  },
  clientModificationDate: {
    key: 'clientModificationDate',
    value: 'Client date change',
  },
  clientModificationInfos: {
    key: 'clientModificationInfos',
    value: 'Client infos change',
  },
  clientCancellation: {
    key: 'clientCancellation',
    value: 'Client cancellation',
  },
  clientRating: { key: 'clientRating', value: 'Client rating' },
  clientPhotosSelected: {
    key: 'clientPhotosSelected',
    value: 'Client photos selection',
  },
  clientExtraPhotosSelected: {
    key: 'clientExtraPhotosSelected',
    value: 'Client extra photos selection',
  },
  clientDownloadPhotos: {
    key: 'clientDownloadPhotos',
    value: 'Client photos download',
  },
  clientSharePhotos: {
    key: 'clientSharePhotos',
    value: 'Client share download',
  },
  clientDownloadMatterport: {
    key: 'clientDownloadMatterport',
    value: 'Client matterport download',
  },
  clientDownloadVideo: {
    key: 'clientDownloadVideo',
    value: 'Client video download',
  },
  sharedDownloadPhotos: {
    key: 'sharedDownloadPhotos',
    value: 'Shared photos download',
  },
  consultationDownloadPhotos: {
    key: 'consultationDownloadPhotos',
    value: 'Consultation photos download',
  },
  consultationSharePhotos: {
    key: 'consultationSharePhotos',
    value: 'Consultation photos download',
  },
  consultationDownloadMatterport: {
    key: 'consultationDownloadMatterport',
    value: 'Consultation matterport download',
  },
  photographerProposal: {
    key: 'photographerProposal',
    value: 'Photographer proposal',
  },
  photographerConfirmation: {
    key: 'photographerConfirmation',
    value: 'Photographer confirmation',
  },
  photographerConfirmationByAdmin: {
    key: 'photographerConfirmationByAdmin',
    value: 'BO / Photographer confirmation',
  },
  photographerFinished: {
    key: 'photographerFinished',
    value: 'Photographer finished',
  },
  photographerFinishedByAdmin: {
    key: 'photographerFinishedByAdmin',
    value: 'BO / Photographer finished',
  },
  photographerUploadFinished: {
    key: 'photographerUploadFinished',
    value: 'Photographer upload finished',
  },
  photographerVideoUploadFinished: {
    key: 'photographerVideoUploadFinished',
    value: 'Photographer video upload finished',
  },
  photographerUploadFinishedByAdmin: {
    key: 'photographerUploadFinishedByAdmin',
    value: 'BO / Photographer upload finished',
  },
  photographerAutoConfirmation: {
    key: 'photographerAutoConfirmation',
    value: 'Photographer auto confirmation',
  },
  photographerBackToConfirmed: {
    key: 'photographerBackToConfirmed',
    value: 'Photographer back to confirmed',
  },
  photographerBackToConfirmedByAdmin: {
    key: 'photographerBackToConfirmedByAdmin',
    value: 'BO / Photographer back to confirmed',
  },
  photographerRefusal: {
    key: 'photographerRefusal',
    value: 'Photographer refusal',
  },
  postproderConfirmation: {
    key: 'postproderConfirmation',
    value: 'Editor confirmation',
  },
  postproderRefusal: { key: 'postproderRefusal', value: 'Editor refusal' },
  postproderConfirmationByAdmin: {
    key: 'postproderConfirmationByAdmin',
    value: 'BO / Editor confirmation',
  },
  postproderPreConfirmationByAdmin: {
    key: 'postproderPreConfirmationByAdmin',
    value: 'BO / Editor pre-confirmation',
  },
  postproderVideoConfirmation: {
    key: 'postproderVideoConfirmation',
    value: 'Video editor confirmation',
  },
  postproderVideoStuck: {
    key: 'postproderVideoStuck',
    value: 'Video marked as stuck by Editor',
  },
  postproderUploadFinished: {
    key: 'postproderUploadFinished',
    value: 'Editor upload finished',
  },
  postproderReedit: { key: 'postproderReedit', value: 'Editor re-edit' },
  reviewerConfirmation: {
    key: 'reviewerConfirmation',
    value: 'Review confirmation',
  },
  reviewerFinished: { key: 'reviewerFinished', value: 'Review finished' },
  adminCreation: { key: 'adminCreation', value: 'Admin creation' },
  adminConfirmation: { key: 'adminConfirmation', value: 'Admin confirmation' },
  adminModification: { key: 'adminModification', value: 'Admin modification' },
  adminModificationDate: {
    key: 'adminModificationDate',
    value: 'Admin date change',
  },
  adminOldCreation: { key: 'adminOldCreation', value: 'Admin old creation' },
  adminUploadFinished: {
    key: 'adminUploadFinished',
    value: 'Admin upload finished',
  },
  adminChangePhotographer: {
    key: 'adminChangePhotographer',
    value: 'Admin photographer change',
  },
  adminCancellation: { key: 'adminCancellation', value: 'Admin cancellation' },
  adminAddressChange: {
    key: 'adminAddressChange',
    value: 'Admin address change',
  },
  adminVideoStuck: {
    key: 'adminVideoStuck',
    value: 'Video stucked by Shoootin',
  },
  adminVideoUnstuck: {
    key: 'adminVideoUnstuck',
    value: 'Video unstucked by Shoootin',
  },
  adminVideoChanges: {
    key: 'adminVideoChanges',
    value: 'Video changes by Shoootin',
  },
  stripePaymentSuccess: {
    key: 'stripePaymentSuccess',
    value: 'Stripe payment success',
  },
  stripePaymentNeedAction: {
    key: 'stripePaymentNeedAction',
    value: 'Stripe payment 3D Secure',
  },
  stripePaymentError: {
    key: 'stripePaymentError',
    value: 'Stripe payment error',
  },
} as const;

export const ShShootingLogs = [
  'clientOrder',
  'clientConfirmation',
  'clientModification',
  'clientModificationDate',
  'clientModificationInfos',
  'clientCancellation',
  'clientRating',
  'clientPhotosSelected',
  'clientExtraPhotosSelected',
  'clientDownloadPhotos',
  'clientSharePhotos',
  'clientDownloadMatterport',
  'clientDownloadVideo',
  'sharedDownloadPhotos',
  'consultationDownloadPhotos',
  'consultationSharePhotos',
  'consultationDownloadMatterport',
  'photographerProposal',
  'photographerConfirmation',
  'photographerConfirmationByAdmin',
  'photographerFinished',
  'photographerFinishedByAdmin',
  'photographerUploadFinished',
  'photographerVideoUploadFinished',
  'photographerUploadFinishedByAdmin',
  'photographerAutoConfirmation',
  'photographerBackToConfirmed',
  'photographerBackToConfirmedByAdmin',
  'photographerRefusal',
  'postproderConfirmation',
  'postproderRefusal',
  'postproderConfirmationByAdmin',
  'postproderPreConfirmationByAdmin',
  'postproderVideoConfirmation',
  'postproderVideoStuck',
  'postproderUploadFinished',
  'postproderReedit',
  'reviewerConfirmation',
  'reviewerFinished',
  'adminCreation',
  'adminConfirmation',
  'adminModification',
  'adminModificationDate',
  'adminOldCreation',
  'adminUploadFinished',
  'adminChangePhotographer',
  'adminCancellation',
  'adminAddressChange',
  'adminVideoStuck',
  'adminVideoUnstuck',
  'adminVideoChanges',
  'stripePaymentSuccess',
  'stripePaymentNeedAction',
  'stripePaymentError',
] as const;

export type ShShootingLog = typeof ShShootingLogs[number];

// WARNING: this file should not be modified, it is generated by running 'yarn generateConfig'

// All Custom order brands
export const ShCustomOrdersConfig = {
  OXFORD: {
    order: 'OXFORD',
    image: 'https://files.shoootin.com/customorder/oxford.png',
    subtitle:
      'Photography, Virtual Staging, Floor Plans and Matterport 3D scans services for Oxford, Level and Spire brokers.',
    lat: 40.764,
    lng: -73.971,
    zoom: 12,
    key: 'OXFORD',
  },
  KALE: {
    order: 'KALE',
    image: 'https://files.shoootin.com/customorder/kale.jpg',
    subtitle:
      'Photography, Virtual Staging, Floor Plans and Matterport 3D scans services for Kale Realty brokers.',
    lat: 41.8781,
    lng: -87.6298,
    zoom: 12,
    key: 'KALE',
  },
  SOTHEBYS_NYC: {
    order: 'SOTHEBYS_NYC',
    image: 'https://files.shoootin.com/customorder/sothebys.png',
    subtitle:
      "Photography, Videos, Virtual Staging, Floor Plans & Matterport 3D Virtual Tours services for Sotheby's.",
    lat: 40.764,
    lng: -73.971,
    zoom: 12,
    key: 'SOTHEBYS_NYC',
  },
  NEWMARK: {
    order: 'NEWMARK',
    image: 'https://files.shoootin.com/customorder/newmark.png',
    subtitle:
      'Photography, Videos, Virtual Staging, Floor Plans & Matterport 3D Virtual Tours services for Newmark brokers.',
    lat: 40.764,
    lng: -73.971,
    zoom: 12,
    key: 'NEWMARK',
  },
  ANCV: {
    order: 'ANCV',
    image: 'https://files.shoootin.com/customorder/ancv.png',
    subtitle:
      'Page dédiée aux partenaires ANCV pour vos commandes de reportages photos',
    lat: 46.232192,
    lng: 2.209666,
    zoom: 5,
    key: 'ANCV',
  },
} as const;

export const ShCustomOrders = [
  'OXFORD',
  'KALE',
  'SOTHEBYS_NYC',
  'NEWMARK',
  'ANCV',
] as const;

export type ShCustomOrder = typeof ShCustomOrders[number];
