import { ShBillingEntityDTO } from './billingEntityDTO';
import { ShApiClient } from '../ShApiClient';
import { ShCompany, ShCountry, ShCurrency, ShPageSize } from '@shoootin/config';
import { ShClientACHDTO, ShCreditCard } from '..';
import { ReactStripeElements } from 'react-stripe-elements';

export type ShBillingEntityFilterParams = {
  skip: number;
  limit: ShPageSize;
  active?: boolean;
  filter?: string;
};
export type ShClientsBillingEntitySearchResponse = {
  billingEntities: ShBillingEntityDTO[];
  count: number;
};

export const ShBillingEntityAPI = {
  getAll: () =>
    ShApiClient.call<{ billingEntities: ShBillingEntityDTO[] }>({
      method: 'GET',
      url: `/client/billing/billingEntity/getAll`,
    }),
  search: (params: ShBillingEntityFilterParams) =>
    ShApiClient.call<ShClientsBillingEntitySearchResponse>({
      method: 'GET',
      url: `/client/billing/billingEntity/search`,
      params,
    }),
  delete: (id: string) =>
    ShApiClient.call({
      method: 'DELETE',
      url: `/client/billing/billingEntity/${id}`,
    }),
  save: (payload: ShBillingEntityDTO) =>
    ShApiClient.call({
      method: 'POST',
      url: `/client/billing/billingEntity`,
      data: payload,
    }),
  makeDefault: (id: string) =>
    ShApiClient.call({
      method: 'POST',
      url: `/client/billing/billingEntity/makeDefault/${id}`,
    }),
};

export type ShClientAddCreditCardResult = {
  status: 'success' | 'error' | 'needAction';
  error?: string;
  clientSecret: string;
};

export type ShClientAddCreditCardPayload = {
  company: ShCompany;
  paymentMethodId?: string;
  paymentIntentId?: string;
  stripeElements?: ReactStripeElements.StripeProps;
};

export type ShClientBuyCrediPayload = {
  billingEntityId: string;
  paymentMethodId?: string;
  paymentIntentId?: string;
  stripeElements?: ReactStripeElements.StripeProps;
};

export const ShBillingCreditCardAPI = {
  delete: (id: string) =>
    ShApiClient.call({
      method: 'DELETE',
      url: `/client/billing/deleteSavedCard/${id}`,
    }),
  addCreditCard: (payload: ShClientAddCreditCardPayload) =>
    ShApiClient.call<ShClientAddCreditCardResult>({
      method: 'POST',
      url: `/client/billing/addCreditCard`,
      data: payload,
    }),
  getAchClientSecret: () =>
    ShApiClient.call<{ clientSecret: string }>({
      method: 'POST',
      url: `/client/billing/getAchClientSecret`,
    }),

  addAchAccount: (paymentMethodId: string) =>
    ShApiClient.call<{ success: boolean }>({
      method: 'POST',
      url: `/client/billing/addAchAccount`,
      data: { paymentMethodId },
    }),
  deleteACH: (id: string) =>
    ShApiClient.call({
      method: 'DELETE',
      url: `/client/billing/deleteACH/${id}`,
    }),
};

export type ShClientInvoiceBillingEntity = {
  company: string;
  department: string;
  address: string[];
  taxNumber: string;
  paymentDelay: number;
};
export type ShInvoiceElement = {
  description: string;
  date: Date;
  user: string;
  shootingAddress: string;
  extraFields: string[];
  totalWithoutTax: number;
  totalWithTax: number;
};
export type ShClientInvoiceDTO = {
  id: string;
  invoiceNb: string;
  typeOfInvoice: string;
  date: Date;
  paid: boolean;
  paymentDate: Date;
  currency: ShCurrency;
  totalWithoutTax: number;
  totalWithTax: number;
  totalTax: number;
  downloadLink: string;
  shootingBillingEntity: ShClientInvoiceBillingEntity;
  clientBillingEntity: ShClientInvoiceBillingEntity;
  extraInfos: string[];
  elements: ShInvoiceElement[];
  accountInformations: string[];
  footerInformations: string;
  lateExtraCharge: number;
  lateExtraChargeRate: number;
  paymentState: string[];
  dueDate: Date;
  isLate: boolean;
};

export type StripeChargeDTO = {
  status: string;
  ach: ShClientACHDTO;
  creditCard: ShCreditCard;
  link: string;
};

export type ShClientBillingViewInvoiceResult = {
  invoice: ShClientInvoiceDTO;
  cards: ShCreditCard[];
  achList: ShClientACHDTO[];
  stripeKey: string;
};

export type ShClientBillingInvoicePayment = {
  status: 'success' | 'needAction' | 'error' | 'processing';
  error?: string;
  clientSecret?: string;
};

export const ShBillingInvoiceAPI = {
  viewInvoice: (id: string) =>
    ShApiClient.call<ShClientBillingViewInvoiceResult>({
      method: 'GET',
      url: `/client/billing/viewInvoice/${id}`,
    }),
  payInvoiceWithCreditCard: (
    id: string,
    paymentMethodId?: string,
    paymentIntentId?: string,
    saveCreditCard?: boolean,
  ) =>
    ShApiClient.call<ShClientBillingInvoicePayment>({
      method: 'POST',
      url: `/client/billing/payInvoiceWithCreditCard/${id}`,
      data: {
        paymentMethodId,
        paymentIntentId,
        saveCreditCard,
      },
    }),
  payInvoiceWithACH: (id: string, paymentMethodId: string) =>
    ShApiClient.call<ShClientBillingInvoicePayment>({
      method: 'POST',
      url: `/client/billing/payInvoiceWithACH/${id}`,
      data: {
        paymentMethodId,
      },
    }),
};

export const ShConsultationBillingInvoiceAPI = {
  viewInvoice: (id: string) =>
    ShApiClient.call<ShClientBillingViewInvoiceResult>({
      method: 'GET',
      url: `/consultation/billing/viewInvoice/${id}`,
    }),
};

export type ShFrontBillingViewInvoiceResult = {
  payable: boolean;
  invoice: ShClientInvoiceDTO;
  stripeKey: string;
  cards: ShCreditCard[];
  achList: ShClientACHDTO[];
  downloadUrl: string;
};

export const ShFrontBillingInvoiceAPI = {
  getStripePublicKey: (company: ShCompany) =>
    ShApiClient.call<{ stripePublicKey: string }>({
      method: 'GET',
      url: `/front/invoice/getStripePublicKey/${company}`,
    }),
  getInvoice: (invoiceId: string) =>
    ShApiClient.call<ShFrontBillingViewInvoiceResult>({
      method: 'GET',
      url: `/front/invoice/viewInvoice/${invoiceId}`,
    }),
  payInvoiceWithCreditCard: (
    id: string,
    paymentMethodId?: string,
    paymentIntentId?: string,
  ) =>
    ShApiClient.call<ShClientBillingInvoicePayment>({
      method: 'POST',
      url: `/front/invoice/payInvoiceWithCreditCard/${id}`,
      data: {
        paymentMethodId,
        paymentIntentId,
      },
    }),

  payInvoiceWithACH: (id: string, paymentMethodId: string) =>
    ShApiClient.call<ShClientBillingInvoicePayment>({
      method: 'POST',
      url: `/front/invoice/payInvoiceWithACH/${id}`,
      data: {
        paymentMethodId,
      },
    }),
};

export type ShClientCreditSummary = {
  totalWithoutTax: number;
  tax: number;
  totalWithTax: number;
  extraCreditInPercent: number;
  extraCreditValue: number;
  remainingExtraCredit: number;
  country: ShCountry;
  currency: ShCurrency;
  billingEntityId: string;
  stripePublicKey: string;
};

export type ShClientBillingCreditPayment = {
  status: 'success' | 'needAction' | 'error';
  error?: string;
  clientSecret?: string;
};

export type ShClientCreditTotalDiscount = {
  country: ShCountry;
  totalWithoutTax: number;
  remaining: number;
  extraCreditInPercent: number;
  maxDiscountOverOneYear: number;
  display: boolean;
};
export type ShClientCreditDiscount = {
  totalDiscounts: Record<ShCountry, ShClientCreditTotalDiscount>;
};

export type ShClientCreditHistory = {
  id: string;
  date: Date;
  country: ShCountry;
  currency: ShCurrency;
  isInvoice: boolean;
  totalWithTax: number;
  totalWithoutTax: number;
  balanceWithTax: number;
  balanceWithoutTax: number;
  description1: string;
  description2: string;
};
export const ShBillingCreditAPI = {
  getCreditDiscounts: () =>
    ShApiClient.call<ShClientCreditDiscount>({
      method: 'GET',
      url: `/client/billing/getCreditDiscounts`,
    }),

  getCreditSummary: (payload: {
    beId: string;
    country: ShCountry;
    amount: number;
  }) =>
    ShApiClient.call<ShClientCreditSummary>({
      method: 'POST',
      url: `/client/billing/getCreditSummary/${payload.beId}/${payload.country}/${payload.amount}`,
    }),
  buyCredit: (payload: {
    beId: string;
    country: ShCountry;
    amount: number;
    paymentMethodId?: string;
    paymentIntentId?: string;
    saveCreditCard?: boolean;
  }) =>
    ShApiClient.call<ShClientBillingCreditPayment>({
      method: 'POST',
      url: `/client/billing/buyCredit/${payload.beId}/${payload.country}/${payload.amount}`,
      data: payload,
    }),

  getHistory: (country: ShCountry) =>
    ShApiClient.call<{ history: ShClientCreditHistory[] }>({
      method: 'GET',
      url: `/client/billing/getCreditHistory/${country}`,
    }),
};
